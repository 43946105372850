import ErrorReporter from '#/lib/ErrorReporter';

const saveFiredOnce = (key: string, ttl?: number): void => {
    const value = `true:${ttl ? Date.now() + ttl : ''}`;
    localStorage.setItem(`fire-once-${key}`, value);
};

const hasFiredOnce = (key: string): boolean => {
    const value = localStorage.getItem(`fire-once-${key}`);
    if (!value) {
        return false;
    }
    const [fired, expires] = value.split(':');
    if (fired !== 'true') {
        return false;
    }
    if (expires && parseInt(expires, 10) < Date.now()) {
        localStorage.removeItem(`fire-once-${key}`);
        return false;
    }
    return true;
};

export const fireOnce = (key: string, callback: () => void, ttl?: number): void => {
    if (typeof window === 'undefined' || !window.localStorage) {
        return;
    }

    if (!hasFiredOnce(key)) {
        saveFiredOnce(key, ttl);
        try {
            callback();
        } catch (error) {
            ErrorReporter.captureError(error);
        }
    }
};
