import { useBrand } from '#/store/BrandStore';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from '#/hooks';
import { useInitStoresByReservationRef } from '#/obePages/tripPage/hooks/useInitStoresByReservationRef';
import { Box, useTheme, Grid2 as Grid } from '@mui/material';
import clsx from 'clsx';
import BreakFrame from '../../components/BreakFrame/BreakFrame';
import ReservationSummaryCard from '../../components/Reservation/ReservationSummaryCard';
import { PageTitle } from '../../components/Typograpgy/Title';
import Events from '../../lib/Events';
import { useTradedoublerSetConfig } from '../../lib/SalesMonitoring/Providers/tradedoubler';
import Quote from '../../lib/api/model/Quote';
import createCheckInLink from '../../lib/createCheckInLink';
import appState from '../../store/AppStateStore';
import ReservationStore from '../../store/ReservationStore';
import TranslationStore from '../../store/TranslationStore';
import tripStore from '../../store/TripStore';
import { BookingCart } from '../../types/BookingCart';
import PageLoader from '#/components/LoadingAnimation/PageLoader';
import bookingFunnelAnalyticsStore from '#/store/BookingFunnelAnalyticsStore';
import reservationStoreInstance from '../../store/ReservationStore';
import ErrorReporter from '#/lib/ErrorReporter';
import analytics from '#/services/analytics';
import { fireOnce } from '#/lib/fireOnce';

export interface DirectBookingConfirmationPageContentProps {
    summary: BookingCart;
    editable?: boolean;
    className?: string;
}

const useFetchCompletedReservation = () => {
    const [{ reservationReference, country }] = useSearchParams<{
        reservationReference: string;
        lastName: string;
        country: string;
    }>();
    return useQuery<Quote | null, Error>({
        queryKey: [`completed-fetch-reservation-${reservationReference}`],
        queryFn: async () =>
            await ReservationStore.fetchReservationV2({
                ref: reservationReference as string,
                useCache: false,
                resume: true,
                country: country as string,
            }),
        initialData: null,
        enabled: Boolean(reservationReference && country),
    });
};

const DirectBookingConfirmationPageContent: React.FC<DirectBookingConfirmationPageContentProps> = ({ summary }) => {
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const { data: quote, isLoading } = useFetchCompletedReservation();
    useTradedoublerSetConfig(28078, {
        products: [
            {
                id: quote?.FleetCategory.CategoryCode,
                price: quote?.TotalPrice.value,
                currency: quote?.TotalPrice.currencyCode,
                name: quote?.FleetCategory.Name,
                grpId: quote?.FleetType.id,
                qty: '1',
            },
        ],
        orderId: quote?.id,
        orderValue: quote?.TotalPrice.value,
        currency: quote?.TotalPrice.currencyCode,
        voucherCode: summary.campaignCode,
        validOn: summary.pickUpDate,
    });

    useEffect(() => {
        if (!isLoading && quote?.ReservationReference) {
            fireOnce(`analytics-purchase-${quote.ReservationReference}`, () => {
                Events.trackPurchase(quote);
                reservationStoreInstance
                    .fetchBooking({
                        reservationReference: quote.ReservationReference,
                        pickUpBranchCode: quote.PickUpLocationCode,
                        lastName: quote.HirerDetails?.LastName,
                    })
                    .then((booking) => {
                        bookingFunnelAnalyticsStore.markStepComplete({ step: 'bookingComplete', booking });
                        analytics.plugins.ecommerce.purchase({ booking });
                        bookingFunnelAnalyticsStore.resetFunnel();
                    })
                    .catch((e) => {
                        ErrorReporter.captureError(e);
                        bookingFunnelAnalyticsStore.resetFunnel();
                    });
            });
        }
    }, [isLoading, quote]);

    const checkInUrl = createCheckInLink(summary);
    const brandStore = useBrand();

    const theme = useTheme();
    useEffect(() => {
        appState.ui.showFooter = false;
        return () => {
            appState.ui.showFooter = true;
        };
    }, []);

    if (isLoading) {
        return <PageLoader hasContainer={true} />;
    }

    return (
        <Box className="animate__animated animate__fadeIn" sx={{ pt: 2 }}>
            <Grid container spacing={{ xs: 1, md: 4 }} pt={2} pb={{ sm: `${theme.settings?.footer.height}px`, md: 0 }}>
                <Grid size={12}>
                    <Box display="flex">
                        <div className="pe-2">
                            <i className="fa fa-2x fa-check-circle text-jucy-green" />
                        </div>
                        <div>
                            <div className="mb-1 fw-bold h6">
                                {summary.isOnRequest ? t('checkin_page.group.request_confirmed_message') : t('checkin_page.group.booking_confirmed_message')}
                            </div>
                            <div className="fw-bold h6 pb-4">
                                {t('checkin_page.group.booking_number')} #{summary?.pickUpLocation?.SiteCode}-{summary?.reservationId}
                            </div>
                        </div>
                    </Box>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        lg: 6,
                    }}
                >
                    <PageTitle sx={{ pb: { xs: 1, md: 3 } }} data-cy="page-title">
                        {summary.isOnRequest ? 'Vehicle requested' : 'Good news your online check-in is now open!'}
                    </PageTitle>
                    {summary.isOnRequest ? (
                        <div className="pb-4">
                            Our crew is checking to see if a vehicle for you is available for your dates so we can get you all locked in. We&apos;ll send you an email (
                            {summary.hirerDetails?.email}) soon.
                            <br />
                            Need to amend your booking? <a href={brandStore.contactPageUrl({ countryCode: summary.pickUpLocation?.CountryCode })}>Contact us here</a>
                        </div>
                    ) : (
                        <>
                            <p className="pb-3">
                                Speed up the time it takes to start your {appState.brand === 'jucy' ? 'JUCY' : 'Star RV'} road trip by completing your check-in, paying the balance and score
                                a $50 experience voucher!
                                <br />
                                Need to amend your booking? <a href={brandStore.contactPageUrl({ countryCode: summary.pickUpLocation?.CountryCode })}>Contact us here</a>
                            </p>
                            <div className="text-center">
                                <a
                                    href={checkInUrl.href}
                                    className={clsx('btn btn-lg', {
                                        'btn-purple': appState.brand === 'jucy',
                                        'btn-sky-blue': appState.brand === 'star',
                                    })}
                                    data-cy="portal-create-account"
                                >
                                    Check-In
                                </a>
                            </div>
                            <p className="small text-muted mt-4">
                                By logging in or creating an account, you agree with our{' '}
                                <a href={brandStore.termsPageUrl({ countryCode: summary.pickUpLocation?.CountryCode })}>Terms of use</a>.
                            </p>
                        </>
                    )}
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        lg: 6,
                    }}
                >
                    <ReservationSummaryCard summary={summary} editable={false} />
                </Grid>
            </Grid>
        </Box>
    );
};
DirectBookingConfirmationPageContent.displayName = 'DirectBookingConfirmationPageContent';

const DirectBookingConfirmationPage = observer(() => {
    const summary = tripStore.summary || ReservationStore.summary;
    const [{ reservationReference }] = useSearchParams<{
        reservationReference: string;
        lastName: string;
        country: string;
    }>();
    const initStoresByReservationRef = useInitStoresByReservationRef();
    const { isLoading } = useQuery({
        queryKey: [`init-store-${reservationReference}`],
        queryFn: async () => {
            if (reservationReference) {
                await initStoresByReservationRef(reservationReference);
                return true;
            }
            return false;
        },
        enabled: Boolean(reservationReference),
    });
    if (isLoading || !summary?.reservationId) {
        return (
            <BreakFrame>
                <PageLoader hasContainer={true} />
            </BreakFrame>
        );
    }
    return (
        <BreakFrame>
            <DirectBookingConfirmationPageContent summary={summary} />
        </BreakFrame>
    );
});
DirectBookingConfirmationPage.displayName = 'DirectBookingConfirmationPage';

export default DirectBookingConfirmationPage;
