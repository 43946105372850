import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { JucyErrorAlert } from '../../../../components/Alert/JucyErrorAlert';
import { ExtrasForm } from '../../../../components/Forms';
import { ExtrasFormProps } from '../../../../components/Forms/ExtrasForm/ExtrasForm';
import { useActiveRoute, useSearchParams, useUpdateRouteParams } from '../../../../hooks';
import Product from '../../../../lib/api/model/Product';
import ProductGroup from '../../../../lib/api/model/ProductGroup';
import { productMapForQueryString } from '../../../../lib/productMapForQueryString';
import productStore from '../../../../store/ProductStore';
import RouteRegistry from '../../../../store/RouteRegistry';
import { BookingCart } from '../../../../types/BookingCart';
import bookingFunnelAnalyticsStore from '#/store/BookingFunnelAnalyticsStore';
import Events from '#/lib/Events';
import { fireOnce } from '#/lib/fireOnce';

export interface TripSelectExtrasTabProps {
    summary?: BookingCart;
    onSelect: ExtrasFormProps['onChange'];
}

const TripSelectExtrasTab: React.FC<TripSelectExtrasTabProps> = observer(({ summary, onSelect }) => {
    const [searchParams] = useSearchParams();
    const activeRoute = useActiveRoute();
    const [activeProductCode, setActiveProductCode] = useState<string | undefined>();
    const proposedProductsMap = summary?.proposedProductsMap;
    const activeParams = useParams();
    const updateRoute = useUpdateRouteParams();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const continueLink = useMemo(
        () =>
            RouteRegistry.getPath(
                activeRoute?.id as string,
                {
                    ...activeParams,
                    activeTab: summary?.userMode === 'direct' ? 'driver' : 'hirer-details',
                },
                {
                    ...(searchParams as Record<string, string>),
                    ...productMapForQueryString(proposedProductsMap),
                }
            ),
        [activeParams, activeRoute?.id, proposedProductsMap, searchParams, summary?.userMode]
    );

    const { secondaryProducts, selectedExtras } = useMemo(() => {
        if (summary?.action === 'edit' && summary.amendments?.secondaryProducts) {
            return {
                secondaryProducts: summary.amendments.secondaryProducts,
                selectedExtras: summary.amendments?.selectedExtras || [],
            };
        }
        return {
            secondaryProducts: summary?.secondaryProducts || [],
            selectedExtras: summary?.selectedExtras || [],
        };
    }, [summary?.action, summary?.amendments?.secondaryProducts, summary?.amendments?.selectedExtras, summary?.secondaryProducts, summary?.selectedExtras]);

    useEffect(() => {
        if (summary && secondaryProducts.length) {
            fireOnce(
                `analytics-extra-options-list-${secondaryProducts
                    .map((group) => group.items.map((item) => item.productCode))
                    .flat()
                    .join('-')}`,
                () => {
                    bookingFunnelAnalyticsStore.markStepComplete({
                        step: 'extrasList',
                        extraProducts: secondaryProducts.map((group) => group.items).flat(),
                    });

                    Events.trackListProducts({
                        ...summary.asSearchFormValues(),
                        listId: 'extra-options',
                        listName: 'OBE Extra Options',
                        items: secondaryProducts.map((group) => group.items).flat(),
                    });
                },
                10000
            );
        }
    }, [secondaryProducts, summary]);

    if (!summary?.secondaryProducts) {
        return (
            <JucyErrorAlert title="Whoops an unexpected error occurred">
                No active trip found
                <br />
                Return to the <Link to="/">Search Page</Link>
            </JucyErrorAlert>
        );
    }

    const onExtrasSelected = useCallback(
        async (item: Product, delta: number) => {
            setActiveProductCode(item.ProductCode);
            onSelect?.(item, delta);
            const currentQty = selectedExtras.find((e) => e.productCode === item.ProductCode)?.qty || 0;
            const updatedSearchParams = {
                ...productMapForQueryString(proposedProductsMap),
                ...(currentQty > 0 ? { [`extras[${item.ProductCode}]`]: `${currentQty}` } : undefined),
            };
            updateRoute(
                {},
                {
                    keepSearch: true,
                    replace: true,
                    search: updatedSearchParams,
                }
            );
        },
        [onSelect, proposedProductsMap, selectedExtras, updateRoute]
    );

    return (
        <ExtrasForm
            nextLink={continueLink}
            onChange={onExtrasSelected}
            extraItems={secondaryProducts.map((g) => ProductGroup.fromV3InsuranceProductGroup(g))}
            selectedItems={selectedExtras.map(Product.fromV3Product) || []}
            isSelecting={productStore.state === 'loading'}
            bundledItems={summary.bundledItems}
            activeProductCode={activeProductCode}
            displayEmailQuote={summary.canEmailQuote}
        />
    );
});
TripSelectExtrasTab.displayName = 'TripSelectExtrasTab';
export default TripSelectExtrasTab;
