import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { FleetTypeSlug, JucyCountryCode } from '@jucy/rentals-common';
import { JucyErrorAlert } from '../../../../components/Alert/JucyErrorAlert';
import { ExcessReductionForm } from '../../../../components/Forms';
import { ExcessReductionFormProps } from '../../../../components/Forms/ExcessReductionForm/ExcessReductionForm';
import { useActiveRoute, useSearchParams } from '../../../../hooks';
import InsuranceProduct from '../../../../lib/api/model/InsuranceProduct';
import { productMapForQueryString } from '../../../../lib/productMapForQueryString';
import appState from '../../../../store/AppStateStore';
import ProductStore from '../../../../store/ProductStore';
import RouteRegistry from '../../../../store/RouteRegistry';
import { BookingCart } from '../../../../types/BookingCart';
import bookingFunnelAnalyticsStore from '#/store/BookingFunnelAnalyticsStore';
import Events from '#/lib/Events';
import { fireOnce } from '#/lib/fireOnce';

export interface TripSelectExcessTabProps {
    summary?: BookingCart;
    onSelect: ExcessReductionFormProps['onSelect'];
}

const TripSelectExcessTab: React.FC<TripSelectExcessTabProps> = observer(({ summary, onSelect }) => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const activeRoute = useActiveRoute();
    const proposedProductsMap = summary?.proposedProductsMap;
    const activeCode = summary?.selectedExcess?.productCode;
    const isSelecting = ProductStore.state === 'loading';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const continueLink = useMemo(
        () =>
            RouteRegistry.getPath(
                activeRoute?.id as string,
                {
                    ...params,
                    activeTab: 'extras',
                },
                {
                    ...(searchParams as Record<string, string>),
                    ...productMapForQueryString(proposedProductsMap),
                }
            ),
        [activeRoute?.id, params, proposedProductsMap, searchParams]
    );
    useEffect(() => {
        appState.orderSummaryFooter.enableNextBtn({
            to: continueLink,
            component: Link,
            disabled: !activeCode || isSelecting,
        });
    }, [activeCode, continueLink, isSelecting]);

    const { excessReductionOptions, selectedExcess, fleetTypeCode } = useMemo(() => {
        if (summary?.action === 'edit' && summary.amendments?.insuranceProducts) {
            return {
                excessReductionOptions: summary.amendments.insuranceProducts,
                selectedExcess: summary.amendments?.selectedExcess,
                fleetTypeCode: summary.amendments?.fleetCategory?.fleetTypeCode as FleetTypeSlug,
            };
        }
        return {
            excessReductionOptions: summary?.insuranceProducts,
            selectedExcess: summary?.selectedExcess,
            fleetTypeCode: summary?.fleetCategory?.fleetTypeCode as FleetTypeSlug,
        };
    }, [
        summary?.action,
        summary?.amendments?.fleetCategory?.fleetTypeCode,
        summary?.amendments?.insuranceProducts,
        summary?.amendments?.selectedExcess,
        summary?.fleetCategory?.fleetTypeCode,
        summary?.insuranceProducts,
        summary?.selectedExcess,
    ]);

    useEffect(() => {
        if (excessReductionOptions?.items.length && summary) {
            fireOnce(
                `analytics-excess-list-${excessReductionOptions.items.map((option) => option.productCode).join('-')}`,
                () => {
                    bookingFunnelAnalyticsStore.markStepComplete({ step: 'excessList', excessProducts: excessReductionOptions.items });
                    Events.trackListProducts({
                        ...summary.asSearchFormValues(),
                        listId: 'excess-options',
                        listName: 'OBE Excess Options',
                        items: excessReductionOptions.items,
                    });
                },
                10000
            );
        }
    }, [excessReductionOptions?.items, summary]);

    if (!excessReductionOptions || !summary) {
        return (
            <JucyErrorAlert title="Whoops an unexpected error occurred">
                No active trip found
                <br />
                Return to the <Link to="/">Search Page</Link>
            </JucyErrorAlert>
        );
    }
    return (
        <ExcessReductionForm
            fleetTypeSlug={fleetTypeCode}
            countryCode={summary.pickUpLocation?.CountryCode as JucyCountryCode}
            fleetCategoryCode={summary.fleetCategory?.code as string}
            nextLink={continueLink}
            onSelect={onSelect}
            excessReductionOptions={excessReductionOptions.items.map((i) => InsuranceProduct.fromV3InsuranceProduct(i))}
            activeCode={selectedExcess?.productCode}
            displayEmailQuote={summary.canEmailQuote}
        />
    );
});
TripSelectExcessTab.displayName = 'TripSelectExcessTab';
export default TripSelectExcessTab;
