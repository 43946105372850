import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { AvailabilityGrid } from '../../../../components/FleetCategory';
import { AvailabilityGridProps } from '../../../../components/FleetCategory/components/AvailabilityGrid/AvailabilityGrid';
import { useSearchParams } from '../../../../hooks';
import AvailabilityStore from '../../../../store/AvailabilityStore';
import { BookingCart } from '../../../../types/BookingCart';
import { AgentAvailabilityCaption, DirectAvailabilityCaption, DirectMoreOptionsCaption } from './TripAvailabilityList';
import bookingFunnelAnalyticsStore from '#/store/BookingFunnelAnalyticsStore';
import Events from '#/lib/Events';
import { fireOnce } from '#/lib/fireOnce';

export interface TripResultsTabProps extends Partial<AvailabilityGridProps> {
    summary?: BookingCart;
    onSelect: AvailabilityGridProps['onSelect'];
}

const TripResultsTab: React.FC<TripResultsTabProps> = observer(({ summary, onSelect, ...props }) => {
    const [searchParams] = useSearchParams<{ fleetCategoryCode?: string }>();
    if (!AvailabilityStore.hasTripInfo) {
        return null;
    }
    const availableFleetCategories = AvailabilityStore.availableFleetCategories;

    useEffect(() => {
        if (availableFleetCategories.length) {
            const fleetCategories = availableFleetCategories.map((f) => f.asV3FleetCategory());
            fireOnce(
                `analytics-availability-list-${fleetCategories.map((category) => `${category.categoryCode}_${category.total.value}`).join('-')}`,
                () => {
                    Events.trackProductImpressionList({
                        ...AvailabilityStore.asTripInfo(),
                        fleetType: AvailabilityStore.fleetType,
                        listId: 'availability',
                        listName: 'OBE Search results',
                        availableFleetCategories: fleetCategories,
                    });
                    bookingFunnelAnalyticsStore.markStepComplete({ step: 'availabilityList', fleetCategories: fleetCategories });
                },
                10000
            );
        }
    }, [availableFleetCategories, searchParams]);
    return (
        <AvailabilityGrid
            {...props}
            highlightedCode={searchParams.fleetCategoryCode}
            activeCode={AvailabilityStore.selectedFleetCategory?.CategoryCode}
            loading={AvailabilityStore.state === 'loading'}
            fleetCategories={availableFleetCategories}
            onSelect={onSelect}
            caption={summary?.userMode === 'agent' ? <AgentAvailabilityCaption /> : <DirectAvailabilityCaption />}
            moreOptionsTitle="More options"
            moreOptionsCaption={summary?.userMode === 'agent' ? undefined : <DirectMoreOptionsCaption />}
            action={summary?.action}
        />
    );
});
TripResultsTab.displayName = 'TripResultsTab';
export default TripResultsTab;
